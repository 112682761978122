// import { main_bg } from "./assets/images";
import { Navbar, Footer, Header } from "./layout";
import CompanyStats from "./sections/CompanyStats";
import Contact from "./sections/Contact";
import Departments from "./sections/Departments";
import Owner from "./sections/Owner";

function App() {
  return (
    <div>
      <Navbar />
      <main className="main">
        <Header />
        <Departments />
        <CompanyStats />
        <Owner />
        <Contact />
      </main>
      <Footer />
    </div>
  );
}

export default App;
