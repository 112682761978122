import React, { useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { logo } from '../../assets/images'

const nav_menu = [
  {
    id: 1,
    text: 'ASOSIY',
    link: '#main'
  },
  {
    id: 2,
    text: 'BO’LIMLAR',
    link: '#departments'
  },
  {
    id: 3,
    text: 'BIZ HAQIMIZDA',
    link: '#companyStats'
  },
  {
    id: 4,
    text: 'ALOQA',
    link: '#contact'
  },
]

const Footer = () => {
  const [activeIndex, setActiveIndex] = useState(0)

  return (
    <div className='mt-[60px] bg-[#151515]'>
      <div className='container gap-[30px] flex flex-col md:flex-row justify-between items-center mb-[50px] md:mb-[20px]'>
        <div className="max-w-[152px] w-[10vw] min-w-[82px]">
          <img src={logo} alt="mme_holding" className="w-full h-full" />
        </div>
        <ul
          className={`flex flex-col items-center md:flex-row md:gap-[30px]`}>
          {nav_menu.map((item, idx) => {
            return (
              <li
                onClick={e => {
                  setActiveIndex(idx)
                }}

                key={item?.id}
                className={`${activeIndex === idx ? 'activeLink' : ''} py-2`} >
                <a href={item?.link} className="">
                  <span className={`hover:text-brand  tracking-wider text-sm font-semibold ${activeIndex === idx ? 'text-brand' : 'text-white'}`}>{item?.text}</span>
                </a>
              </li>
            )
          })}
        </ul>
      </div>
      <div className='container '>
        <div className='border-t pt-4 flex flex-col sm:flex-row items-center justify-between  '>
          <p className='text-xs font-medium text-center mb-[20px]'>© Barcha huquqlar 1SONIA tomonidan himoyalangan</p>
          {/* <a href='../../../priv.html' className='text-xs font-medium  mb-[20px]'>Privacy & Policy</a> */}
          <a href='../../../privacy&policy.html' className='text-xs font-medium  mb-[20px]'>Privacy & Policy</a>
        </div>
      </div>
    </div>
  )
}

export default Footer