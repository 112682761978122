import React from 'react'
import { card_arrow } from '../assets/images'

const Button = ({ children, small }) => {
  return (
    <button className={`relative flex gap-1 max-w-max w-full items-center py-5 px-6 group ${small ? 'py-[6px] px-[6px]' : 'py-5 px-6'}`}>
      <div className={`absolute transition-all duration-300 left-0 top-1/2 -translate-y-[50%] rounded-[50%] group-hover:w-full bg-brand/50
      ${small ? 'h-[30px] w-[30px]' : 'h-[60px] w-[60px]'}
      `}></div>
      <span
        className={`relative text-xs  sm:text-sm inline-block ${small ? 'font-bold' : 'font-medium sm:font-bold'} text-white leading-none text-left`}>{children}</span>
      {small ? <img src={card_arrow} alt="card-arrow" /> : null}
    </button>
  )
}

export default Button