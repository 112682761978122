import React, { useState } from "react";
import { phone, user } from "../assets/images";
import Holding from "../components/holding.component";

//bot token
const telegram_bot_id = "5943491573:AAFtzhJhVnPuS79zJGgT32hE-rm8YC78lX0";
//chat id
const chat_id = -1001826958307;

const defaultVal = {
  name: "",
  phone: "",
  message: "",
};
const Contact = () => {
  const [loading, setloading] = useState(false);
  const [formData, setFormData] = useState(defaultVal);
  const [errorTxt, setErrorTxt] = useState("");

  async function postData(url = "", data) {
    !data && setErrorTxt("Iltimos maydonlarni to'ldiring");
    try {
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: JSON.stringify(data),
      });
      setErrorTxt("Muvaffaqiyatli jonatildi!");
      setTimeout(() => {
        setErrorTxt("");
      }, 5000);

      return response.json();
    } catch (error) {
      console.log(error);
      setErrorTxt("Xatolik yuz berdi iltimos qayta urinib koring!");
    }
  }

  function handleChange(e) {
    const value = e.target.value;
    const name = e.target.name;

    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  }

  const handleSubmit = (e) => {
    setErrorTxt("");
    setloading(true);
    e.preventDefault();
    try {
      const message =
        "\nWebsite: MME \nIsmi: " +
        formData.name +
        "\nTelefon: " +
        formData.phone +
        "\nIzoh: " +
        formData.message;
      //
      postData(
        "https://api.telegram.org/bot" + telegram_bot_id + "/sendMessage",
        {
          chat_id: chat_id,
          text: message,
        }
      );
    } catch (e) {
      console.log(e, "error in submit handler");
    } finally {
      setloading(false);
      setFormData(defaultVal);
    }
  };

  return (
    <>
      <div
        id="contact"
        className="container mt-[60px] md:mt-[130px] mb-[60px] lg:mb-[80px] xl:mb-[120px]"
      >
        <p className="sm:text-center mb-5 lg:mb-[130px] w-full">
          <Holding className="text-[26px] font-medium leading-[130%] md:text-[40px] text-stroke-white-05 lg:text-stroke-white ">
            Savol va takliflar bormi?
          </Holding>
        </p>

        <div className="flex flex-col gap-[60px] md:gap-0 md:flex-row md:justify-center">
          <form
            onSubmit={handleSubmit}
            className="mx-auto md:bg-[#201F1F] md:mx-0 max-w-[460px] md:px-[30px] md:py-[35px]"
          >
            <p className="mb-[18px] text-sm font-poppins md:text-[34px] md:font-medium md:leading-[50px] md:text-center">
              Bizga xabar yo'llang
            </p>

            <div className="flex flex-col gap-3">
              <label className="py-2 px-3 flex items-center gap-2 border border-grey-400 bg-dark rounded max-w-[396px]">
                <img src={user} alt="user" />
                <input
                  type="text"
                  required
                  value={formData.name}
                  name="name"
                  pattern="[a-zA-Z0-9]+"
                  minLength="3"
                  maxLength="20"
                  onChange={handleChange}
                  placeholder="Ism"
                  className="bg-transparent outline-none w-full "
                />
              </label>
              <label className="py-2 px-3 flex items-center gap-2 border border-grey-400 bg-dark rounded max-w-[396px]">
                <img src={phone} alt="phone" />
                <input
                  type="tel"
                  placeholder="Telefon"
                  required
                  minLength="7"
                  pattern="^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$"
                  value={formData.phone}
                  name="phone"
                  onChange={handleChange}
                  className="bg-transparent outline-none w-full "
                />
              </label>
            </div>

            <textarea
              placeholder="Xabar yozish"
              required
              minLength="10"
              value={formData.message}
              name="message"
              onChange={handleChange}
              className="min-h-[134px] mt-4 border border-grey-400 py-2 px-3 bg-dark rounded outline-none w-full max-w-[396px]"
            ></textarea>

            <button
              disabled={!formData.name || !formData.phone || !formData.message}
              className="bg-brand disabled:opacity-50 hover:text-white hover:border-white shadow-sm border w-full max-w-[396px] py-[10px] mt-6 rounded border-[#ffffff66]"
            >
              {loading ? "Yuborilmoqda..." : "Yuborish"}
            </button>
            {errorTxt ? (
              <span className="font-poppins text-[red]">{errorTxt}</span>
            ) : null}
          </form>

          <div className="max-w-[526px] w-[50%] hidden overflow-hidden md:block">
            <iframe
              className="w-full h-full"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2998.737502362766!2d69.20843061557105!3d41.27105101116154!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38ae89676f71b73d%3A0xf4effd2e06a465c9!2s1SONiA%20MChJ!5e0!3m2!1sen!2s!4v1676812943617!5m2!1sen!2s"
              title="location"
              style={{ border: "0" }}
              allowFullScreen=""
              loading="lazy"
              target="_parent"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>

      <div className="max-w-[396px] mx-auto overflow-hidden rounded md:hidden">
        <iframe
          className="w-full h-full max-h-[300px] min-h-[250px] "
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2998.737502362766!2d69.20843061557105!3d41.27105101116154!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38ae89676f71b73d%3A0xf4effd2e06a465c9!2s1SONiA%20MChJ!5e0!3m2!1sen!2s!4v1676812943617!5m2!1sen!2s"
          title="location"
          style={{ border: "0" }}
          allowFullScreen=""
          loading="lazy"
          target="_parent"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </>
  );
};

export default Contact;
