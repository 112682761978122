import React from "react";
import { scroll_arrow } from "../../assets/images";
// import { header_bg, header_bg_mobile } from '../../assets/images'
import Button from "../../components/button.component";
import HeaderCarousel from "../../components/header-carousel.component";
import Holding from "../../components/holding.component";
import MME from "../../components/mme.component";

const Header = () => {
  return (
    <div
      id="main"
      className="header-bg-mobile relative sm:header-bg sm:bg-left-top md:bg-center pt-[80px] md:min-h-screen"
    >
      <div className="container py-[40px] pt-[55px] ">
        {/* subtitle */}
        <h2 className="font-russo text-xl text-center md:text-3xl xxl:text-4xl mb-6">
          Bir necha kompaniyalarni o’z ichiga olgan
        </h2>

        {/* title */}
        <h1 className="font-russo text-xl text-center flex justify-center flex-wrap gap-0 sm:gap-2 ">
          <MME className="text-[64px] md:text-[100px] xxl:text-[140px]" />

          <span className="inline-flex flex-col justify-start items-start self-center">
            <Holding className="text-stroke-white md:text-stroke-white-2 text-2xl md:text-[70px] xxl:text-[100px]">
              Holding
            </Holding>
            <span className="uppercase text-white font-bold text-[9px] md:text-[15px] xxl:text-[22px] leading-[11px] font-jakarta md:-translate-y-1">
              sahifasiga xush kelibsiz
            </span>
          </span>
        </h1>

        {/* content */}
        {/* <div className="flex flex-col md:grid grid-flow-col gap-10 mt-14"> */}
        {/* <div className=" mt-14"> */}
        {/* left */}

        <div className="mt-14 flex flex-col lg:flex-row items-center gap-5">
          <HeaderCarousel />

          <div className="self-end mx-auto mt-10 lg:mt-0">
            <Button>Batafsil biling</Button>
            <div className="relative max-w-[417px] w-full mt-5 lg:mt-12">
              <div className="bg-brand h-5/6 w-[3px] absolute top-[50%] -translate-y-1/2"></div>
              <p className="pl-4 text-sm font-medium w-full">
                MME Holding IT-biznes, ishlab chiqarish, sotuv do’konlariga
                asoslangan, Osiyo bozorlarda yetakchi kompaniya hisoblanadi. MME
                holding kompaniyasiga 2019 - yil asos solingan.
              </p>
            </div>
          </div>
        </div>
        {/* right */}
        {/* <div className=''>
            
          </div> */}
        {/* </div> */}
        {/* arrow */}
        <div className="absolute hidden lg:block md:top-0 left-2/4 md:translate-y-[95vh] -translate-x-[50%]">
          <div className="text-center animate-myAnim">
            <img
              src={scroll_arrow}
              className="inline-block"
              alt="scroll_arrow"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
