import React from 'react'

const MME = ({ className = '' }) => {
  return (
    <span className={`text-stroke-white font-semibold font-poppins leading-[100%] xxl:leading-[130%] text-brand ${className}`}>
      MME
    </span>
  )
}

export default MME