import React from "react";
import Button from "../components/button.component";
import Company from "../components/company.component";
import Holding from "../components/holding.component";
import MME from "../components/mme.component";
import { lastoriaUz, lastoriaDantel, texnostyle } from "../assets/images";

const mockData = [
  {
    heading: "LaStoria",
    text: "Kelin ko'ylaklarining yangi va zamonaviy modellarini ishlab chiqarish va sotish bilan shugullanadi. Turkiya, Dubay va yana dunyoning ko'pgina shaxarlarida sotuv ofislari mavjud. Taniqli brendlar qatoriga kirib borayotgan kompaniya instagram profilida 100 mingdan ortiq obunachiga ega.",
    createdDate: "4-may 2012",
    image: lastoriaUz,
  },
  {
    heading: "TexnoStyle",
    text: "TexnoStyle brendi ostida (SD-015, Sd-009, SD-021 modelidagi) chiqish eshiklarini ishlab chiqarish xamda Xitoy, Rossiya, Belorussiyaning brend kompaniyalaridan import qilinadigan eshiklar savdosi bilan shugullanadi.",
    createdDate: "1-iyun 2021",
    image: texnostyle,
  },
  {
    heading: "LaStoria Dantel",
    text: "To’y va marosim liboslari uchun Xitoy,Dubay va Turkiyadan import qilingan maxsus gipyur maxsulotlari savdosi bilan shugullanadi.",
    createdDate: "10-yanvar 2023",
    image: lastoriaDantel,
  },
];

const Departments = () => {
  return (
    <div id="departments" className="container pt-[30px] md:pt-[90px]">
      {/* title */}
      <h2 className="flex flex-col sm:flex-row sm:gap-2  items-center justify-center">
        <MME className="font-semibold text-[42px] md:text-[60px] leading-[60px] lg:text-4xl" />
        <Holding className="text-[26px] leading-[40px] md:text-[45px] xl:text-[60px] xl:leading-[120%] text-stroke-white-05 lg:text-stroke-white">
          Holding bo'limlari
        </Holding>
      </h2>
      {/* subtitle */}
      <div className=" mx-auto md:w-full grid grid-flow-row md:grid-flow-col md:grid-cols-2 justify-between flex-col md:flex-row lg:items-start gap-6 mt-10 lg:mt-[100px]">
        <div className="flex flex-col items-start justify-start">
          <span className="text-grey-600 inline-block font-semibold text-xs tracking-[0.15em]">
            Kompaniyalar
          </span>
          <p className="font-poppins font-medium text-xl  lg:text-[40px] lg:leading-[135%] lg:font-semibold text-white tracking-[0em]">
            MME holding bo'limidagi kompaniyalar
          </p>
        </div>
        <div className="xl:max-w-[480px] w-full justify-self-end flex flex-col md:block justify-center items-center ">
          <Button>Barcha kompanyalar bilan tanishib chiqing</Button>
        </div>
      </div>
      {/* cards */}
      <div className="grid place-content-center gap-y-10 md:grid-cols-2 lg:grid-cols-3 gap-x-7 mt-10 lg:mt-[100px]">
        {mockData.map((item, i) => (
          <Company
            heading={item.heading}
            createdDate={item.createdDate}
            title={item.text}
            image={item.image}
          ></Company>
        ))}
      </div>
    </div>
  );
};

export default Departments;
