import React from "react";
import { company } from "../assets/images";
import Button from "./button.component";

const Company = ({ title, image, heading, createdDate }) => {
  return (
    <div className="max-w-[460px] ">
      <div className="img -mb-9 ">
        <img src={image || company} alt="company" />
      </div>
      <div className="relative bg-[#181818] content ml-auto w-[87%] xl:w-[80%] min-w-[250px] lg:max-w-[380px] p-2 md:p-[15px] flex flex-col">
        <span className=" inline-block text-grey-600 text-base font-medium">
          Tashkil topgan | {createdDate}
        </span>
        <h3 className="  text-white font-semibold text-sm md:mt-[8px] lg:text-xl font-poppins">
          {heading}
        </h3>
        <p className="font-medium text-base text-grey-500 mb-3 mt-2">{title}</p>
        <Button small={true}>Batafsil bilish</Button>
      </div>
    </div>

    // <div className='max-w-[315px]'>
    //   <div className="img">
    //     <img src={company} alt="company" />
    //   </div>
    //   <div className="content ml-auto p-y[10px] pl-[0px] bg-black pr-0 max-w-[260px]">
    //     <div className='bg-black mb-2  -mt-[50px] relative w-[calc(10px+100%)]'>
    //       <span className='ml-1 inline-block text-grey-600 text-base font-medium'>Tashkil topgan  |   15 Dekabr  2022</span>
    //       <h3 className='ml-1  text-white font-semibold text-sm'>TexnoStyle kompanyasi</h3>
    //     </div>
    //     <div className='pl-[4px]'>
    //       <p className="font-medium text-base text-grey-500 mb-3">But I must explain to you how all this
    //         mistaken idea of denouncing pleasure and
    //         praising pain was born and I will give you a
    //         complete account of the system</p>
    //       <Button small={true}>Batafsil bilish</Button>
    //     </div>
    //   </div>
    // </div>
  );
};

export default Company;
