import React, { useCallback, useEffect, useRef, useState } from 'react'
import { slider_img1, slider_img2 } from '../assets/images'


const HeaderCarousel = () => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const img_data = [
    slider_img1,
    slider_img2,
    slider_img1,
    slider_img2,
  ]

  let carouselIndicatorLength = (100 / img_data.length) + 1

  const myRef = useRef(null)

  const [curr, setCurr] = useState(0)



  const greaterThanNine = (num) => {
    if (num < 10 && num > 0) {
      return '0' + num
    }
    return num || num + 1
  }

  const nextNum = curr + 2 > img_data.length ? greaterThanNine(1) : greaterThanNine(curr + 2)

  const move = 100 * curr

  const prev = () => {
    myRef.current.classList.add('opacity-0', '-translate-y-full');

    setTimeout(() => {
      setCurr((cur) => (cur === 0 ? img_data.length - 1 : cur - 1))
      myRef.current.classList.remove('-translate-y-full');
      myRef.current.classList.add('translate-y-full');
    }, 300);

    setTimeout(() => {
      myRef.current.classList.remove('opacity-0', 'translate-y-full');
    }, 450);


  }

  const next = useCallback(() => {
    myRef.current.classList.add('opacity-0', 'translate-y-full', 'ease-linear');

    setTimeout(() => {
      setCurr((cur) => (cur === img_data.length - 1 ? 0 : cur + 1))
      myRef.current.classList.remove('translate-y-full');
      myRef.current.classList.add('-translate-y-full');
    }, 300);

    setTimeout(() => {
      myRef.current.classList.remove('opacity-0', '-translate-y-full', 'ease-linear');
    }, 450);

  }, [img_data])

  useEffect(() => {
    const autoslide = setInterval(next, 3000);

    return () => {
      clearInterval(autoslide)
    }
  }, [next])


  return (
    <>
      <div className='max-w-[600px] relative flex gap-3 -translate-x-3 sm:translate-x-0 '>
        <div className='w-[200px] h-[200px] sm:w-[240px] sm:h-[240px] md:w-[305px] md:h-[305px] absolute top-2/4 -translate-y-2/4 left-0  bg-brand/40 rounded-[50%]' />

        <div className='relative  ml-[30px] sm:ml-[80px] min-w-[240px] max-w-[350px] flex justify-start items-center md:max-w-none overflow-hidden'>
          <img ref={myRef} src={img_data[curr]} alt="our-team" className='duration-200 transition-all' />
        </div>

        <div className='flex flex-col items-center relative right-0 text-sm font-semibold gap-2'>
          <span className='cursor-pointer rotate-90' onClick={prev}>{greaterThanNine(curr + 1)}</span>
          <div className='w-[3px] h-full bg-white overflow-hidden'
          >
            <div style={{ height: `${carouselIndicatorLength}%`, transform: `translateY(${move}%)` }}
              className={`transition-all w-full bg-[#702DE3] `}></div>
          </div>
          <span className='cursor-pointer rotate-90' onClick={next}>{nextNum}</span>
        </div>

      </div>
    </>
  )
}

export default HeaderCarousel