import React from 'react'

const CheckedIcon = () => {
  return (
    <div className='grid place-items-center bg-brand w-4 h-4 rounded-full'>
      <div className='-translate-y-[1px] w-[7px] h-[12px] h- border-[#181818] border-[3px] translate-x-[1px] border-l-0 border-t-0 rotate-45'></div>
    </div>
  )
}

export default CheckedIcon