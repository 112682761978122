import React, { Fragment } from "react";
import { globe } from "../assets/images";
import CheckedIcon from "../components/checked-icon";

const data = ["Meditsina", "Restoran", "Avto", "Mashhurlar"];

const mockData = [
  { title: "Meditsina", count: "27%" },
  { title: "Restoran", count: "15%" },
  { title: "Avto", count: "58%" },
];

const CompanyStats = () => {
  return (
    <div
      id="companyStats"
      className="container  md:flex items-center justify-center py-[60px] "
    >
      <div className="mb-5  sm:w-[500px] sm:mx-auto md:w-[58%] -mx-2">
        {/* <img src={globe} alt="globe" className="w-full h-full max-w-[670px]" /> */}
        <iframe
          className="max-w-[680px] w-full xl:h-[400px] lg:h-[350px] md:h-[300px] sm:h-[260px] h-[220px]"
          width="680"
          height="400"
          src="https://www.youtube.com/embed/PGA0iZTRiXI"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </div>
      <div className="md:pl-7 md:w-[45%]">
        <div className="flex flex-col">
          <span className="mb-[10px] inline-block text-grey-600 text-base font-medium">
            Tashkil topgan | 20 Oktabr 2020
          </span>
          <h3 className="font-poppins text-white font-semibold text-[22px] leading-[30px] lg:text-xl xl:text-2xl">
            1SONiA <br /> kompanyasi
          </h3>
          <p className="font-medium text-base tracking-normal lg:text-sm text-grey-500 mb-6 mt-2">
            IT sohasiga asoslangan “1SONiA” nomi ostidagi mobil ilovaning
            faoliyati bilan shugullanadi. Ilovada 4 ta yonalish mavjud bolib
            bular “Meditsina”, “Restoran”, “Avto” va “Mashxurlar”. Xar bir
            bolimning o’z yo’nalishi va vazifalari bor. Insonlarga xizmat
            ko’rsatishda qulaylik tashkil etish, ularni ortiqcha ovvoragarchilik
            va sarsongarchilikdan xalos etish. Kun davomida bajariladigan ko’p
            ishlarini masofadan xal etishda komaklashishdan iborat. Bundan
            tashqari xizmat egalari uchun mijozlar sonini oshirish. Namunali
            xizmat ko’rsatuvchi professional mutaxassislarni ommaga yanada
            tanitishga xizmat qiladi.
          </p>

          <div className=" flex flex-col gap-3">
            {data.map((item, idx) => {
              return (
                <div key={idx} className="flex items-center gap-[14px]">
                  <CheckedIcon />
                  <p className="text-sm font-semibold">{item}</p>
                </div>
              );
            })}
          </div>
          <div className="flex justify-between items-center mt-5 lg:mt-9">
            {mockData.map((item, idx, arr) => {
              return (
                <Fragment key={idx}>
                  <div className="flex flex-col my-border">
                    <p className="text-[22px] lg:text-[35px] lg:leading-[140%] leading-[28px] font-semibold text-white">
                      {item.count}
                    </p>
                    <span className="text-grey-600 text-[10px] font-medium lg:text-[11px]">
                      {item.title}
                    </span>
                  </div>
                  <div
                    className={`h-[15px] w-[1px] bg-[#ffffff33] ${
                      item === arr.length ? "hidden" : ""
                    }`}
                  ></div>
                </Fragment>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyStats;
