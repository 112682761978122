import React from "react";
import {
  clock,
  dart,
  email,
  instagram,
  money,
  owner_mobile,
  rocket,
  whatsapp,
} from "../assets/images";

const images = [
  {
    image: clock,
    title:
      "Vaqtni qadrlaydi va muvaffaqiyatga erishishda uning ahamiyatini tushunadi.",
  },
  {
    image: rocket,
    title:
      "Ishlab chiqarishni tezlashtirish innovatsiyalarga, moslashuvchanlikka va raqobatchilardan oldinda bo'lishga olib keladi, deb hisoblaydi.",
  },
  {
    image: money,
    title:
      "Moliyani strategik tarzda boshqaradi va xodimlarni o'qitish, marketing vatadqiqot va ishlanmalarga sarmoya kiritadi.",
  },
  {
    image: dart,
    title:
      "Aniq, o'lchanadigan, erishish mumkin, tegishli va vaqt bilan bog'liq maqsadlarni qo'yadi va taraqqiyotni kuzatadi.",
  },
];

const Owner = () => {
  return (
    <div className="container flex flex-col gap-[60px] py-4 md:flex-row items-center  justify-center">
      {/* owner */}
      <div className="min-w-[300px] max-w-[320px]  mx-auto h-max rounded-[12px] overflow-hidden">
        <div className="-mb-16">
          <img src={owner_mobile} alt="owner" />
        </div>
        {/* content */}
        <div className="bg-dark relative px-[12px] pt-6 pb-4 text-white rounded-t-xl">
          <div className="absolute -top-3 right-6 md:right-5 z-10 py-[14px] bg-brand overflow-hidden w-max mx-auto px-[28px] rounded-[10px] -skew-x-[15deg]">
            <p className="text-md font-medium font-poppins skew-x-[15deg]">
              Mehriddin Mahmudov
            </p>
          </div>

          <div className=" py-[14px] border border-brand overflow-hidden px-[44px] rounded-[10px] -skew-x-[15deg]">
            <p className="text-md font-poppins skew-x-[15deg]">
              MME holding asoschisi
            </p>
          </div>

          <p className="text-sm text-center mt-4 mb-3">
            MME holding ijtimoiy tarmoqlari
          </p>

          <div className="flex items-center gap-[10px] justify-center">
            <div className="w-[34px] h-[34px] border rounded-full p-[7px] grid place-content-center">
              <img className="" src={instagram} alt="instagram" />
            </div>
            <div className="w-[34px] h-[34px] border rounded-full p-[7px] grid place-content-center">
              <img className="" src={whatsapp} alt="whatsapp" />
            </div>
            <div className="w-[34px] h-[34px] border rounded-full p-[7px] grid place-content-center">
              <img className="" src={email} alt="email" />
            </div>
          </div>
        </div>
      </div>
      {/* cards */}
      {/* title */}
      {/* <div className='md:w-2/4 lg:w-auto'> */}
      <div className="md:w-2/4 lg:w-auto xxl:w-[60%] lg:justify-center xl:justify-start flex flex-col lg:flex-row flex-wrap  gap-3 xl:gap-6">
        <h1 className="text-[22px] font-poppins lg:text-center xl:text-left w-full leading-[33px] text-white mb-[14px] lg:mb-[33px]">
          Mehriddin Mahmudov
        </h1>
        {images.map((item, idx) => {
          return (
            <div
              key={idx}
              className="flex lg:max-w-[370px] bg-dark border-2 border-grey-600 rounded-lg p-2 sm:p-4 relative min-h-[130px] w-full"
            >
              <img
                className="max-h-[100px] sm:max-h-[150px] absolute h-full w-full object-contain object-right-bottom right-0 bottom-0"
                src={item.image}
                alt="clock"
              />
              <p className="text-xs font-poppins max-w-[60%]">{item.title}</p>
            </div>
          );
        })}
      </div>
      {/* </div> */}
    </div>
  );
};

export default Owner;
